import React, { FC, StrictMode } from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import TermsAndConditions from "./TermsAndConditions";
import initializeTracer from "shared/tracer";
import { Config, loadConfig } from "shared/config";

const router = (config: Config) =>
  createBrowserRouter([
    {
      path: "/",
      element: <App config={config} />,
    },
    {
      path: "termsandconditions",
      element: <TermsAndConditions />,
    },
  ]);

const ErrorFallback: FC<any> = ({ error }) => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={resetBoundary}>Try again</button>
    </div>
  );
};

loadConfig()
  .then((config) => {
    try {
      initializeTracer.initializeTracer(config);
    } catch (e) {
      console.log("could not load tracer", e);
    }

    ReactDOM.render(
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <StrictMode>
          <RouterProvider router={router(config)} />
        </StrictMode>
      </ErrorBoundary>,
      document.getElementById("root")
    );
  })
  .catch(() => {
    ReactDOM.render(
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <StrictMode>
          <p>Could not load config file</p>
        </StrictMode>
      </ErrorBoundary>,
      document.getElementById("root")
    );
  });
