import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import AuthRoot from "./AuthRoot";
import { Config } from "shared/config";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(0, 160, 40, 1)",
    },
  },
});

const App: React.FC<{config: Config}> = ({config}) => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AuthRoot config={config}/>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
