import React from "react";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import LaptopIcon from "@mui/icons-material/Laptop";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import BankIdThisDeviceLogin from "./BankIdThisDeviceLogin";
import BankIdMobileLoginQr from "./BankIdMobileLoginQr";
import {
  cancelLogin,
  startBankIdLogin,
  checkIfBankIdLoginStarted,
} from "shared/bankidApi";
import { isMobileBrowser } from "shared/utils";
import { Config } from "shared/config";

interface BankIdFrameProps {
  config: Config;
  sx?: SxProps<Theme>;
}

const getLoginMethodFromLoginHint = (login_hint: string) => {
  if (login_hint === "" && isMobileBrowser()) {
    return "this_device";
  }
  return login_hint === "this_device" ? "this_device" : "mobile";
};

const BankIdFrame: React.FC<BankIdFrameProps> = ({ sx = [], config }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const login_challenge = searchParams.get("login_challenge") as string;
  const login_hint = searchParams.get("login_hint") ?? "";
  const [loginMethod, setLoginMethod] = React.useState(
    getLoginMethodFromLoginHint(login_hint)
  );
  const [loginFailed, setLoginFailed] = React.useState(false);
  const [bankIdOpened, setBankIdOpened] = React.useState(false);
  const [qrCode, setQrCode] = React.useState<string | null>(null);
  const bankIdUrl = React.useRef("");
  const [userMessage, setUserMessage] = React.useState("");
  const pollingCallback = React.useRef<NodeJS.Timeout | null>(null);
  const is_only_webapp = React.useMemo(() => {
    const { is_only_webapp } = config;
    if (!is_only_webapp) return false;
    if (is_only_webapp) return is_only_webapp.toLowerCase() == "true";
    return false;
  }, [config]);

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newLoginMethod: string | null
  ) => {
    if (newLoginMethod !== null) {
      setLoginMethod(newLoginMethod);
    }
  };

  const onBackClick = async (_event: React.MouseEvent<HTMLButtonElement>) => {
    if (login_challenge !== null) {
      try {
        await cancelLogin(login_challenge);
      } catch {
        // no meaningful error handling here, we just go back which should reset
        // everything
      }
    }
    setBankIdOpened(false);
    history.back();
  };

  const renderedLoginMethod = () => {
    switch (loginMethod) {
      case "this_device":
        console.log("this_device", is_only_webapp);

        return (
          <BankIdThisDeviceLogin
            bankIdOpened={bankIdOpened}
            setBankIdOpened={setBankIdOpened}
            onBackClick={onBackClick}
            loginFailed={loginFailed}
            startBankIdLogin={async (personalNumber?: string) => {
              await startBankIdLogin(
                login_challenge,
                pollingCallback,
                loginMethod,
                bankIdUrl,
                setBankIdOpened,
                setLoginFailed,
                setUserMessage,
                setQrCode,
                personalNumber,
                is_only_webapp
              );
            }}
            bankIdUrl={bankIdUrl}
            userMessage={userMessage}
          />
        );
      case "mobile":
        return (
          <BankIdMobileLoginQr
            onBackClick={onBackClick}
            loginFailed={loginFailed}
            qrCode={qrCode}
            userMessage={userMessage}
          />
        );
    }
  };

  const titleText = () => {
    switch (loginMethod) {
      case "this_device":
        return "Logga in med BankID";
      case "mobile":
        return "Logga in med mobilt BankID";
    }
  };

  React.useEffect(() => {
    if (loginMethod === "mobile") {
      console.log("this_device", is_only_webapp);
      startBankIdLogin(
        login_challenge,
        pollingCallback,
        loginMethod,
        bankIdUrl,
        setBankIdOpened,
        setLoginFailed,
        setUserMessage,
        setQrCode,
        undefined,
        is_only_webapp
      );
    } else if (loginMethod === "this_device") {
      checkIfBankIdLoginStarted(
        login_challenge,
        pollingCallback,
        loginMethod,
        setBankIdOpened,
        setLoginFailed,
        setUserMessage
      );
    }
    return () => {
      if (pollingCallback.current) {
        clearTimeout(pollingCallback.current);
        pollingCallback.current = null;
      }
    };
  }, [login_challenge, loginMethod]);

  return (
    <Card variant="outlined" sx={sx}>
      <CardHeader
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
        }}
        title={titleText()}
      />
      <CardContent>
        <Stack spacing={2}>
          <ToggleButtonGroup
            color="primary"
            value={loginMethod}
            onChange={handleChange}
            exclusive
            fullWidth
          >
            <ToggleButton value="this_device">
              <LaptopIcon />
              <Box component="span" sx={{ m: 1 }}>
                BankID
              </Box>
            </ToggleButton>
            <ToggleButton value="mobile">
              <PhoneAndroidIcon />
              <Box component="span" sx={{ m: 1 }}>
                Mobilt BankID
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
          {renderedLoginMethod()}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default BankIdFrame;
