import React from "react";
import BankIdFrame from "./BankIdFrame";
import {
  Container,
  Stack,
  Typography,
  Link as MaterialLink,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CustomerLogo from "./images/CustomerLogo";
import { Config } from "shared/config";

const AuthRoot: React.FC<{config: Config}> = ({config}) => {
  return (
    <Container maxWidth="xs">
      <Stack spacing={2}>
        <CustomerLogo
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}
        />
        <BankIdFrame config={config}/>
        <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
          Inloggningstjänst levererad av Xenit AB
        </Typography>
        <MaterialLink
          variant="subtitle2"
          sx={{ textAlign: "center" }}
          component={RouterLink}
          to="/termsandconditions"
        >
          Terms and Conditions
        </MaterialLink>
      </Stack>
    </Container>
  );
};

export default AuthRoot;
